<template>
  <div class="loginpage">
    <p class="loginP">账号绑定</p>
    <van-form @submit="onSubmit">
      <div class="inline">
        <van-field
          v-model="phone"
          type="number"
          name="phone"
          placeholder="请输入法人手机号"
          :rules="[{ required: true, validator }]"
        />
      </div>
      <!-- 正常登录 -->
      <div class="commet">
        <!-- 填写验证码 -->
        <div class="inline">
          <van-field
            v-model="code"
            name="code"
            placeholder="请输入验证码"
            :rules="[{ required: true }]"
          />
          <div class="huoqYZM" @click="getYanZhM" v-if="showGetYZM">
            获取验证码
          </div>
          <div class="huoqYZM" v-if="!showGetYZM">
            {{ oneMinate }}s后重新获取
          </div>
        </div>
      </div>
      <div style="margin: 50px">
        <van-button block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
    <van-overlay :show="loading">
      <div class="loading">
        <van-loading />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import url from "@/service/url-config";
export default {
  data() {
    return {
      phone: "",
      code: "",
      password: "",
      newPass: "",
      showGetYZM: true, //显示获取验证码
      oneMinate: 60,
      timer: null,
      codeLogin: true, //账号验证码登录
      isCommit: true, //正常登录
      showXieYe: false, //显示弹窗
      radio: null,
      showregist: true,
      openidKey: "",
      path: "",
      loading: true,
    };
  },
  created() {
    this.openidKey = this.$route.query.openidKey;
    this.path = this.$route.query.path;
    this.wxOpenid();
  },
  methods: {
    //手机号校验
    validator(val) {
      return /^(1)\d{10}$/.test(val);
    },
    //获取验证码计时器
    getYanZhM() {
      if (this.validator(this.phone)) {
        this.showGetYZM = false;
        this.sendSMS();
        this.oneMinate = 60;
        if (this.timer) clearInterval(this.timer);
        this.timer = setInterval(() => {
          this.oneMinate -= 1;
          if (this.oneMinate < 1) {
            clearInterval(this.timer);
            this.showGetYZM = true;
            this.timer = null;
          }
        }, 1000);
      } else {
        this.$dialog.alert({
          message: "请先输入手机号",
        });
      }
    },
    //获取验证码
    sendSMS() {
      this.$http
        .post(url.sendSMS, {
          phone: this.phone,
        })
        .then((res) => {
          if (res.code != 200) {
            this.$dialog.alert({
              message: res.msg,
            });
          }
        });
    },

    wxOpenid() {
      let param = {
        appId: "wx",
        appVersion: "1.0.0",
        channel: "53",
        openidKey: this.openidKey,
        clientId: "wqll",
      };
      this.$http.post(url.wxOpenid, param).then((res) => {
        if (res.code == 200) {
          const data = res.data ? res.data : res;
          if (!data.access_token) return;
          localStorage.setItem("wql_refresh_token", data.refresh_token);
          localStorage.setItem("wql_real_token", data.access_token);
          localStorage.setItem("wql_user_id", data.user_id);
          localStorage.setItem("wql_token", data.access_token);
          localStorage.setItem(
            "wql_expires_in",
            data.expires_in + parseInt(new Date().getTime() / 1000)
          );
          this.$router.push(this.path);
          this.$toast("登录成功");
        } else {
          this.loading = false;
        }
      });
    },
    onSubmit(values) {
      this.loading = true;
      let param = {
        ...values,
        appId: "wx",
        appVersion: "1.0.0",
        channel: "53",
        openidKey: this.openidKey,
        clientId: "wqll",
      };
      this.$http.post(url.wxLogin, param).then((res) => {
        this.loading = false;
        if (res.code == 200) {
          const data = res.data ? res.data : res;
          if (!data.access_token) return;
          localStorage.setItem("wql_refresh_token", data.refresh_token);
          localStorage.setItem("wql_real_token", data.access_token);
          localStorage.setItem("wql_user_id", data.user_id);
          localStorage.setItem("wql_token", data.access_token);
          localStorage.setItem(
            "wql_expires_in",
            data.expires_in + parseInt(new Date().getTime() / 1000)
          );
          this.$router.push(this.path);
          this.$toast("登录成功");
        } else {
          this.$toast(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.loginpage {
  width: 100%;
  box-sizing: border-box;
  padding: 30px 20px;

  .loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loginP {
    font-weight: 500;
    color: #333333;
    line-height: 110px;
    font-size: 18px;
    padding-left: 20px;
  }

  .inline {
    display: flex;
    border-bottom: 1px solid rgba(235, 237, 240);
    margin: 0 20px;

    .van-cell::after {
      border-bottom: 0;
    }

    .huoqYZM {
      width: 150px;
      font-size: 14px;
      line-height: 50px;
      color: rgba(0, 132, 255, 1);
    }
  }

  .toggle {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    font-size: 12px;
    line-height: 25px;
    color: rgba(0, 132, 255, 1);
  }

  .regist {
    text-align: center;
    font-size: 14px;
    line-height: 50px;
    color: rgba(0, 132, 255, 1);
  }

  .radio {
    font-size: 12px;

    /deep/ .van-icon {
      font-size: 14px;
    }
  }

  .van-action-sheet {
    height: 50vh;

    .content {
      padding: 20px;
      font-size: 12px;
      font-weight: 400;

      ._p {
        text-indent: 2em;
      }
    }
  }
}
</style>